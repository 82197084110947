.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.card-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mathjax{
  padding: 10px;
}

.image{
  object-fit: cover;
}
